import React from 'react';
import { useQuery } from 'utils/react-query';

import queries from 'containers/Artist/queries';

import SectionBlock from 'components/Globals/SectionBlock';
import PreviewCard from 'components/Globals/PreviewCard';
import Loader from 'components/Loader';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import ClaimProfileCard from '../ClaimProfileCard';

import classes from './PeopleAlsoViewed.module.scss';

const trackingData = {
  section: SECTIONS.PEOPLE_ALSO_VIEWED,
  component: COMPONENTS.ARTIST_CARD,
};

const seeAlltrackingData = {
  ...trackingData,
  component: COMPONENTS.SEE_ALL_CTA,
};

const PeopleAlsoViewedList = ({ entityType, data, isLoggedIn }) => (
  <>
    {data?.map(artist => (
      <PreviewCard
        key={artist?.id}
        entity={artist}
        entityType={entityType}
        trackingData={{
          ...trackingData,
          ...(artist?.hierarchy && {
            meta: {
              criteria: artist?.hierarchy,
            },
          }),
        }}
      />
    ))}
    {!isLoggedIn && <ClaimProfileCard isVertical />}
  </>
);

const PeopleAlsoViewed = ({ entity, entityType, sectionProps = {}, limit }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { permissions } = usePageContext();
  const { isMobile } = useDeviceTypeLayouts();
  const isLoggedIn = permissions?.isLoggedIn;
  const apiLimit = isLoggedIn ? 12 : 11;

  const { data, isLoading } = useQuery(
    queries.getArtistsWithSimilarProfessions({
      id: entity?.id,
      limit: limit || apiLimit,
    }),
  );

  if (isLoading) {
    return (
      <div className={classes.root}>
        <Loader />
      </div>
    );
  }

  if (!data?.data?.length) {
    return null;
  }

  return (
    <SectionBlock
      title={t(`${TP}.FN_PEOPLE_ALSO_VIEWED`)}
      seeAllTrackingData={seeAlltrackingData}
      styles={{ title: classes.title, seeAllLink: classes.seeAllLink, leftBoxIcon: classes.leftBoxIcon }}
      {...sectionProps}
    >
      {!isMobile ? (
        <div className={classes.similarArtists}>
          <PeopleAlsoViewedList entityType={entityType} data={data?.data} isLoggedIn={isLoggedIn} />
        </div>
      ) : (
        <HorizontalScrollIndicators className={classes.horizontalScrollItems} isArrowsEnabled={false}>
          <PeopleAlsoViewedList entityType={entityType} data={data?.data} isLoggedIn={isLoggedIn} />
        </HorizontalScrollIndicators>
      )}
    </SectionBlock>
  );
};

export default PeopleAlsoViewed;
