import React, { useMemo } from 'react';
import classnames from 'classnames';

import Content from 'components/Globals/Layout/StubPage/Content';
import EntityFeaturedReview from 'components/Globals/EntityFeaturedReview';
import FeaturedArtistEntities from 'components/Artist/Display/FeaturedArtistEntities';
import RepertoireListing from 'components/Artist/Display/Repertoire/RepertoireListing';
import EntityBioSummary from 'components/Globals/EntityBioSummary';
import EntityContactBlock from 'components/Globals/EntityContactBlock';
import CastingToolPromo from 'components/Globals/CastingToolPromo';

import { SIZE as PREVIEW_CARD_SIZE, VARIANTS as PREVIEW_CARD_VARIANTS } from 'components/Globals/PreviewCard';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import usePageContext from 'utils/hooks/usePageContext';
import { useQuery } from 'utils/react-query';

import { ENTITY_TYPE, FEATURED_ENTITY_SECTIONS } from 'constants/index';

import globalQueries from 'containers/Globals/queries';

import classes from './ArtistPremiumSections.module.scss';

const ArtistInfoAndCollaboration = ({ entity, entityType, countOfFeaturedSections }) => {
  const { isDesktop } = useDeviceTypeLayouts();

  return (
    <div
      className={classnames(
        classes.infoAndCollaborators,
        classes[`infoAndCollaborators__columns_${countOfFeaturedSections}`],
      )}
    >
      {isDesktop && (
        <div className={classes.infoAndCollaborators__left}>
          <EntityBioSummary
            entity={entity}
            entityType={entityType}
            trackingData={{ section: SECTIONS.BIOGRAPHY }}
            sectionProps={{
              enableSeeAll: false,
              wrapContent: true,
            }}
          />
          {(entity?.stats?.contacts?.exists || entity?.stats?.agencies?.exists) && (
            <EntityContactBlock
              sectionProps={{
                enableSeeAll: false,
                wrapContent: true,
              }}
              maxSocialContactCount={4}
              isOverview
            />
          )}
        </div>
      )}
      {Object.values(FEATURED_ENTITY_SECTIONS).map(section => {
        if (entity?.stats?.preferredEntities?.[section]?.visibility) {
          return (
            <FeaturedArtistEntities
              key={section}
              entity={entity}
              section={section}
              size={PREVIEW_CARD_SIZE.SMALL}
              variant={PREVIEW_CARD_VARIANTS.LANDSCAPE}
              sectionProps={{
                wrapContent: isDesktop,
              }}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

const ArtistPremiumSections = () => {
  const { entityType, entity } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();

  const hasReviews =
    entity?.id &&
    entityType === ENTITY_TYPE.ARTIST &&
    (entity?.stats?.reviews?.featured?.exists || entity?.stats?.reviews?.production?.exists);

  const { data: hasBio = false } = useQuery(
    globalQueries.getEntityBioDetails({
      entityType,
      entityId: entity?.id,
      queryConfig: { enabled: true, select: response => response?.data?.length > 0 },
    }),
  );

  const hasContactInfo = useMemo(
    () =>
      (entity?.stats?.agencies?.exists && entity?.stats?.customizations?.agency?.visibility) ||
      entity?.stats?.contacts?.exists,
    [entity?.stats],
  );

  const countOfFeaturedSections = useMemo(
    () => Object.values(entity?.stats?.preferredEntities).filter(section => section.visibility).length,
    [entity?.stats?.preferredEntities],
  );

  const sections = useMemo(
    () => [
      {
        component: EntityBioSummary,
        placement: {
          desktop: {
            hide: true,
          },
          tablet: {
            column: 1,
            order: 1,
          },
          mobile: {
            column: 1,
            order: 1,
          },
        },
        props: {
          trackingData: { section: SECTIONS.BIOGRAPHY },
        },
        sectionProps: {
          enableSeeAll: false,
          wrapContent: true,
          marginTopSmall: !isDesktop,
        },
        showSection: hasBio,
      },
      {
        component: EntityFeaturedReview,
        placement: {
          desktop: {
            column: 1,
            order: 1,
          },
          tablet: {
            column: 1,
            order: 2,
          },
          mobile: {
            column: 1,
            order: 2,
          },
        },
        props: {
          hideTitle: isDesktop,
        },
        sectionProps: {
          marginTopSmall: !isDesktop,
        },
        showSection: hasReviews,
      },
      {
        component: EntityContactBlock,
        placement: {
          desktop: {
            hide: true,
          },
          tablet: {
            column: 1,
            order: 3,
          },
          mobile: {
            column: 1,
            order: 3,
          },
        },
        sectionProps: {
          enableSeeAll: false,
          wrapContent: true,
          marginTopSmall: !isDesktop,
        },
        props: {
          maxSocialContactCount: 4,
          isOverview: true,
        },
        showSection: hasContactInfo,
      },
      {
        component: ArtistInfoAndCollaboration,
        placement: {
          desktop: {
            column: 1,
            order: 2,
          },
          tablet: {
            column: 1,
            order: 5,
            hide: countOfFeaturedSections === 0,
          },
          mobile: {
            column: 1,
            order: 5,
            hide: countOfFeaturedSections === 0,
          },
        },
        sectionProps: {
          marginTopSmall: !isDesktop,
        },
        props: {
          countOfFeaturedSections,
        },
      },
      {
        component: RepertoireListing,
        props: {
          inline: true,
        },
        sectionProps: {
          enableSeeAll: false,
          marginTopSmall: !isDesktop,
        },
        placement: {
          desktop: {
            column: 1,
            order: 3,
          },
          tablet: {
            column: 1,
            order: 4,
          },
          mobile: {
            column: 1,
            order: 4,
          },
        },
        showSection:
          entity?.stats?.customizations?.repertoire?.visibility ||
          entity?.stats?.customizations?.futureRepertoire?.visibility,
      },
      {
        component: CastingToolPromo,
        props: {
          section: SECTIONS.ENTITY_HEADER,
          component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA,
        },
        placement: {
          desktop: {
            column: 1,
            order: 4,
          },
          tablet: {
            column: 1,
            order: 6,
          },
          mobile: {
            column: 1,
            order: 6,
          },
        },
        sectionProps: {
          marginTopSmall: !isDesktop,
        },
      },
    ],
    [isDesktop, hasBio, hasReviews, hasContactInfo, countOfFeaturedSections],
  );

  return <Content entity={entity} entityType={entityType} sections={sections} equalColumnWidth={false} />;
};

export default ArtistPremiumSections;
