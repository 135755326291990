import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/uiLibrary/Typography';
import Modal from 'components/uiLibrary/Modal';
import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import useTracking from 'components/Globals/Analytics';
import { TP } from 'constants/index';

import { useReviewDetails } from 'utils/reviews';
import { useTranslation } from 'src/i18n';

import { ReviewProductionInfo } from '../ReviewCard';
import classes from './ReviewModal.module.scss';

const ReviewModal = ({ content, onCloseModal, trackingData, withProductionInfo = false }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();

  const details = useReviewDetails(content);

  const handleCloseModal = () => {
    onCloseModal();
    track.click({
      ...trackingData,
      component: COMPONENTS.REVIEW_MODAL,
      subComponent: SUB_COMPONENTS.CLOSE_CTA,
    });
  };

  return (
    <Modal
      isOpen
      onClose={handleCloseModal}
      title={withProductionInfo ? t(`${TP}.FN_PRODUCTION_REVIEW`) : t(`${TP}.FN_PROFILE_REVIEW`)}
      styles={{
        modalRoot: classes.modal,
        modalContainer: classes.modal__container,
        drawerPaper: classes.drawerPaper,
      }}
      allowMobileDrawer
    >
      {content?.production?.id && withProductionInfo && (
        <div className={classes.sticky}>
          <ReviewProductionInfo production={content?.production} className={classes.productionInfo} />
        </div>
      )}
      <div className={classes.reviewModal}>
        {(details?.title || details?.highlight) && (
          <Typography type="medium" size="14">
            {details?.title || details?.highlight}
          </Typography>
        )}

        {details?.content && (
          <Typography className={classes.reviewModal_description} size="14" color="secondary" variant="p">
            {details?.content}
          </Typography>
        )}
      </div>
    </Modal>
  );
};

ReviewModal.propTypes = {
  content: PropTypes.object,
  onCloseModal: PropTypes.func,
};

export default React.memo(ReviewModal);
