import { useMemo } from 'react';

import { DATE_FORMATS } from 'constants/index';
import { createDate } from 'utils/date';

import { isValidHttpUrl } from '../common';

export const useReviewDetails = review => {
  const reviewDate = useMemo(() => {
    const reviewedAt = review?.reviewedAt;

    if (reviewedAt) {
      return createDate(reviewedAt).format(DATE_FORMATS.DATE_FULL_LABEL);
    }

    const dayObject = {
      day: review?.reviewDay ?? 1,
      month: review?.reviewMonth ? review?.reviewMonth - 1 : undefined,
      year: review?.reviewYear,
    };

    if (dayObject?.month === undefined || !dayObject?.year === undefined) {
      return null;
    }

    const newDate = new Date(dayObject?.year, dayObject?.month, dayObject?.day);
    return createDate(newDate).format(DATE_FORMATS.DATE_FULL_LABEL);
  }, [review]);

  const { hostname, link } = useMemo(() => {
    const reviewURL = review?.reviewUrl;
    const isValidURL = isValidHttpUrl(reviewURL);

    if (!isValidURL) {
      return { hostname: reviewURL, link: reviewURL };
    }

    const urlObject = new URL(reviewURL);

    return {
      hostname: urlObject?.hostname,
      link: urlObject?.href,
    };
  }, [review]);

  return {
    reviewDate,
    link,
    hostname,
    author: review?.signature,
    title: review?.title,
    content: review?.content,
    highlight: review?.highlight,
    excerpt: (review?.highlight || review?.content)?.substring(0, 120),
  };
};
