import { useMemo } from 'react';
import { useQuery } from 'utils/react-query';
import { reduce, omit } from 'lodash';

import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';

import { PROFILES_ENDPOINT, ORGANIZATIONS } from 'constants/endpointConstants';
import { EDIT_PAGE_TYPE } from 'constants/consts';
import { ENTITY_TYPE } from 'constants/index';
import * as API from '../API';

export function useNotificationCounts(entity, entityId) {
  const queryKey = ['GET_NOTIFICATION_COUNTS', entity, entityId];

  const endpoint = useMemo(() => {
    if (!entityId) {
      return null;
    }

    switch (entity) {
      case EDIT_PAGE_TYPE.ARTIST:
        return `${PROFILES_ENDPOINT}/notifications`;
      case EDIT_PAGE_TYPE.ORGANIZATIONS:
        return `${ORGANIZATIONS}/notifications`;
      default:
        return null;
    }
  }, [entity, entityId]);

  return useQuery(queryKey, () => API.getOne(endpoint, entityId).then(response => response?.data), {
    enabled: !!endpoint,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
}

export function useProfileNotificationCounts() {
  const { profileData, entityType } = useGetTargetProfile();

  const entity = useMemo(() => {
    if (entityType === ENTITY_TYPE.ORGANIZATION) {
      return {
        type: EDIT_PAGE_TYPE.COMPANY,
        id: profileData?.id,
      };
    }
    if (entityType === ENTITY_TYPE.PROFILE) {
      return {
        type: EDIT_PAGE_TYPE.ARTIST,
        id: profileData?.id,
      };
    }
    return null;
  }, [entityType, profileData?.id]);
  const { data } = useNotificationCounts(entity?.type, entity?.id);
  // Removed as we are not using it on V3 TODO: Change this on V4 version
  const profileNotifications = omit(data, ['changeRequest', 'redMask', 'drafts', 'greyMask', 'missingPrograms']);
  const profileNotificationsCount = reduce(profileNotifications, (sum, n) => sum + n, 0);
  const profileGreyMaskCount = data?.greyMask;
  const profileRedMaskCount = data?.redMask;
  const profileDraftProductionsCount = data?.drafts;
  return {
    profileNotifications,
    profileNotificationsCount,
    notificationsProfileType: entity?.type,
    profileGreyMaskCount,
    profileRedMaskCount,
    profileDraftProductionsCount,
  };
}
