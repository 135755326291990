/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'utils/react-query';
import classnames from 'classnames';

import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';
import Modal from 'components/uiLibrary/Modal';
import Button from 'components/uiLibrary/Button';
import Typography from 'components/uiLibrary/Typography';
import Image from 'components/uiLibrary/Image';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import FeaturedArtists from 'components/Artist/Display/FeaturedArtists';

import queries, { useUpdateRegistrationMutation } from 'containers/Accounts/queries';

import { ARTIST_TYPE_ID, TP } from 'constants/index';
import { REGISTRATION_STEPS } from 'containers/Accounts/constants';

import { useTranslation, Trans } from 'src/i18n';

import { useDialogs } from 'utils/hooks/useDialogs';
import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { TRACK_EVENTS, ONBOARDING_EVENT_IDS } from 'utils/tracking';
import { IS_CHECKOUT_APPEARED } from 'constants/cookieConstants';
import { getCookie } from 'utils/cookie';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import LocoIcon from 'public/images/ob-icon.svg';

import classes from './OnboardingModal.module.scss';

const onboardingBasePath = 'https://public.operabase.com/images/artists/onboarding';

const ONBOARDING_STEPS = {
  [ARTIST_TYPE_ID]: [
    {
      title: `${TP}.FN_ARTIST_ONBOARDING_STEP_1_TITLE`,
      subTitle: `${TP}.FN_ARTIST_ONBOARDING_STEP_1_SUBTITLE`,
      image: isMobile =>
        isMobile
          ? `${onboardingBasePath}/casting_tool_mobile_20240207T034948555Z.webp`
          : `${onboardingBasePath}/casting_tool_20240207T100005775Z.webp`,
    },
    {
      title: `${TP}.FN_ARTIST_ONBOARDING_STEP_2_TITLE`,
      subTitle: `${TP}.FN_ARTIST_ONBOARDING_STEP_2_SUBTITLE`,
      image: isMobile =>
        isMobile
          ? `${onboardingBasePath}/future_repertoire_mobile_20240207T034948555Z.webp`
          : `${onboardingBasePath}/future_repertoire_20240207T034948555Z.webp`,
    },
    {
      title: `${TP}.FN_ARTIST_ONBOARDING_STEP_3_TITLE`,
      leftIcon: 'red_mask',
      subTitle: `${TP}.FN_ARTIST_ONBOARDING_STEP_3_SUBTITLE`,
      image: isMobile =>
        isMobile
          ? `${onboardingBasePath}/request_red_mask_mobile_20240207T034948555Z.webp`
          : `${onboardingBasePath}/request_red_mask_20240207T100005775Z.webp`,
    },
    {
      title: `${TP}.FN_ARTIST_ONBOARDING_STEP_4_TITLE`,
      subTitle: `${TP}.FN_ARTIST_ONBOARDING_STEP_4_SUBTITLE`,
      image: isMobile =>
        isMobile
          ? `${onboardingBasePath}/audition_showreel_mobile_20240207T034948555Z.webp`
          : `${onboardingBasePath}/audition_showreel_20240207T034948555Z.webp`,
    },
    {
      title: `${TP}.FN_ARTIST_ONBOARDING_STEP_5_TITLE`,
      subTitle: `${TP}.FN_ARTIST_ONBOARDING_STEP_5_SUBTITLE`,
      image: isMobile =>
        isMobile
          ? `${onboardingBasePath}/contact_representation_mobile_20240207T034948555Z.webp`
          : `${onboardingBasePath}/contact_representation_20240207T034948555Z.webp`,
    },
    {
      title: `${TP}.FN_ARTIST_ONBOARDING_STEP_6_TITLE`,
      subTitle: `${TP}.FN_ARTIST_ONBOARDING_STEP_6_SUBTITLE`,
      isShowFeaturedArtists: true,
    },
    {
      title: `${TP}.FN_ARTIST_ONBOARDING_STEP_7_TITLE`,
      leftIcon: 'edit',
      subTitle: `${TP}.FN_ARTIST_ONBOARDING_STEP_7_SUBTITLE`,
      image: isMobile =>
        isMobile
          ? `${onboardingBasePath}/identity_verification_mobile_20240207T034948555Z.webp`
          : `${onboardingBasePath}/identity_verification_20240207T100005775Z.webp`,
    },
  ],
};

const OnboardingModal = ({ profileId, profileTypeId }) => {
  const { t } = useTranslation('NS_ONBOARDING');
  const track = useNewTracking();
  const userData = useUserData();
  const { isMobile } = useDeviceTypeLayouts();
  const { isOnboardingModal, setIsOnboardingModal, setEmailAccountSetting } = useDialogs();
  const { subscriptionNudgesPopup } = useNotificationPopup();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { data: registrations, refetch: refetchRegistrations } = useQuery(
    queries.getRegistrations({ userId: userData?.id, queryConfig: { enabled: !!userData?.id } }),
  );

  const activeRegistration = registrations?.data?.find(reg => reg?.entities?.profile?.id === profileId);
  const { status, step } = activeRegistration || {};
  const { has_accepted_disclaimer, has_seen_disclaimer, has_confirmed_disclaimer, is_onboarded } = status || {};

  const { mutate: updateRegistrationMutation } = useUpdateRegistrationMutation({
    onSuccess: () => refetchRegistrations(),
  });
  const isCheckoutAppeared = getCookie(IS_CHECKOUT_APPEARED) === 'true';
  useEffect(() => {
    if (
      activeRegistration &&
      step >= REGISTRATION_STEPS.subscriptionForm &&
      !is_onboarded &&
      isCheckoutAppeared &&
      has_accepted_disclaimer &&
      has_seen_disclaimer &&
      has_confirmed_disclaimer &&
      !subscriptionNudgesPopup?.isOpen
    ) {
      setIsOnboardingModal(true);
    }
  }, [
    activeRegistration,
    has_accepted_disclaimer,
    has_confirmed_disclaimer,
    has_seen_disclaimer,
    isCheckoutAppeared,
    is_onboarded,
    setIsOnboardingModal,
    step,
    subscriptionNudgesPopup,
  ]);

  const handleBackStep = () => {
    setCurrentStepIndex(currentStepIndex - 1);
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: ONBOARDING_EVENT_IDS.BACK_BUTTON,
          register_type: 'artist',
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };

  const handleNextStep = () => {
    if (currentStepIndex === ONBOARDING_STEPS?.[profileTypeId]?.length - 1) {
      const payload = {
        ...activeRegistration,
        status: {
          ...activeRegistration?.status,
          is_onboarded: true,
        },
      };

      updateRegistrationMutation(payload);
      setIsOnboardingModal(false);
      if (userData.passwordSetupRequired) {
        setEmailAccountSetting({ isOpen: true });
      }
    } else {
      setCurrentStepIndex(currentStepIndex + 1);
    }

    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: ONBOARDING_EVENT_IDS.NEXT_BUTTON,
          register_type: 'artist',
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };

  return (
    <Modal
      isOpen={isOnboardingModal}
      onClose={() => {}}
      className={isMobile ? classes.drawerContainer : ''}
      styles={{ modalContainer: classes.onboarding, modalRoot: classes.onboarding__modalRoot }}
      allowMobileDrawer
    >
      <div className={classes.onboarding__header}>
        <Image
          src={LocoIcon}
          alt="Operabase Home"
          lazy={false}
          className={classes.onboarding__header_logo}
          disableCloudinary
          disableNextImage
        />
      </div>
      <div className={classes.onboarding__container}>
        <div className={classes.onboarding__titleSection}>
          {ONBOARDING_STEPS?.[profileTypeId]?.[currentStepIndex]?.leftIcon && (
            <SpriteIcon
              icon={ONBOARDING_STEPS?.[profileTypeId]?.[currentStepIndex]?.leftIcon}
              className={classes.onboarding__titleSection_icon}
              size={isMobile ? 24 : 25}
            />
          )}
          <Typography variant="h4" className={classes.onboarding__title}>
            <Trans
              i18nKey={ONBOARDING_STEPS?.[profileTypeId]?.[currentStepIndex]?.title}
              components={{
                b: <span className={classes.onboarding__bold} />,
              }}
              ns="NS_ONBOARDING"
            />
          </Typography>
        </div>
        <Typography size={isMobile ? 14 : 16} variant="p" color="secondary">
          <Trans
            i18nKey={ONBOARDING_STEPS?.[profileTypeId]?.[currentStepIndex]?.subTitle}
            components={{
              b: <span className={classes.onboarding__bold} />,
            }}
            ns="NS_ONBOARDING"
          />
        </Typography>
        {ONBOARDING_STEPS?.[profileTypeId]?.[currentStepIndex]?.isShowFeaturedArtists && (
          <div className={classes.onboarding__featuredArtists}>
            <FeaturedArtists limit={6} openNewTab />
          </div>
        )}
        {ONBOARDING_STEPS?.[profileTypeId]?.[currentStepIndex]?.image?.(isMobile) && (
          <div className={classes.onboarding__imageContainer}>
            <Image
              src={ONBOARDING_STEPS?.[profileTypeId]?.[currentStepIndex]?.image?.(isMobile)}
              alt="Onboarding Image"
              width={420}
              className={classes.onboarding__featureImage}
              disableCloudinary
              disableNextImage
            />
          </div>
        )}
      </div>
      <div className={classes.onboarding__footer}>
        <div className={classes.onboarding__footer_actions}>
          {currentStepIndex > 0 && (
            <Button
              className={classes.onboarding__footer_backBtn}
              shape="rectangle"
              variant="secondary"
              onClick={handleBackStep}
            >
              {t(`${TP}.FN_BACK`)}
            </Button>
          )}
          <Button
            className={classnames(classes.onboarding__footer_nextBtn, {
              [classes.onboarding__footer_fullWidthBtn]: !currentStepIndex,
            })}
            shape="rectangle"
            onClick={handleNextStep}
          >
            {t(`${TP}.m_NEXTSTEP`)} {currentStepIndex + 1}/{ONBOARDING_STEPS?.[profileTypeId]?.length}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OnboardingModal;
