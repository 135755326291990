/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import useQuickView, { QUICK_VIEW_TYPES } from 'components/Globals/Layout/QuickView';

import { useMediaQuery } from '@mui/material';
import usePageContext from 'utils/hooks/usePageContext';
import ImpressionTracker from 'utils/components/impressionTracker';
import { ENTITY_TYPE } from 'constants/index';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';
import useTracking from 'components/Globals/Analytics';
import LocationAndDates from './LocationAndDates';
import Producers from './Producers';
import CastCrew from './CastCrew';
import ProductionTags from './ProductionTags';
import MusicalWorkInfo from './MusicalWorkInfo';
import ProductionPeriod from './ProductionPeriod';

import classes from './ProSlug.module.scss';

const ICONS = {
  COLLAPSE: 'expand_less',
  EXPAND: 'expand_more',
  RIGHT: 'chevron_right',
};

const Icon = ({ icon }) => <SpriteIcon icon={icon} className={classes.activeIcon} />;

const ProSlug = ({
  showWorkDetails = false,
  production,
  profile,
  entityType,
  shouldSectionExpand = true,
  isGraph = false, // TODO: Rename to isProArtistView
  redirectToProductionPage = false,
  isDefaultExpanded = false,
  highlightWorkId,
  trackingData,
}) => {
  const completeTrackingData = {
    ...trackingData,
    entityId: production?.id,
    entityName: production?.name,
    entityType: ENTITY_TYPE.PRODUCTION,
  };
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
  const { navigate } = usePageContext();
  const trackingClasses = useTrackingClickStyles(completeTrackingData, false);
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useMediaQuery('(max-width: 800px)');
  const track = useTracking();
  const { showQuickView } = useQuickView();

  const openQuickPreview = useCallback(
    ({ productionId }) => {
      showQuickView({
        type: QUICK_VIEW_TYPES.PRODUCTION,
        data: {
          entityId: productionId,
          quickViewContextId: profile?.id,
          isSeasonView: false,
        },
        onClose: () => setIsExpanded(false),
      });
      // NOTE: showQuickView is not required as depedency for this hook
    },
    [profile?.id],
  );

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const onClickHandler = event => {
    if (redirectToProductionPage) {
      event.preventDefault();
      event.stopPropagation();
      navigate.to(navigate.getLinkProps({ entity: production, entityType: ENTITY_TYPE.PRODUCTION }));
    }
    track.click({
      ...completeTrackingData,
      meta: {
        isOpen: !isExpanded,
      },
    });
    if (shouldSectionExpand) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (isMobile && isExpanded) {
      openQuickPreview({ productionId: production.id });
    }
  }, [isMobile, isExpanded]);

  return (
    <ImpressionTracker data={completeTrackingData}>
      <div
        className={classnames(classes.parent, trackingClasses, { [classes.active]: !!isExpanded })}
        onClick={onClickHandler}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        {shouldSectionExpand && (
          <LinkButton
            styles={{ root: classnames(classes.arrowIcon, { [classes.expandedLine]: !!isExpanded }) }}
            variant="text"
            leftIcon={
              <Icon {...(isMobile ? { icon: ICONS.RIGHT } : { icon: isExpanded ? ICONS.COLLAPSE : ICONS.EXPAND })} />
            }
            disableHover
            disableUnderline
            skipTracking
          />
        )}
        <div className={classes.components}>
          <div className={classes.leftBlock}>
            {showWorkDetails && (
              <MusicalWorkInfo
                isExpanded={isExpanded}
                showArtistPerformedDates={showWorkDetails}
                production={production}
                profile={profile}
                isHovered={isHovered || isExpanded}
                isGraph={isGraph}
                highlightWorkId={highlightWorkId}
                trackingData={trackingData}
              />
            )}
            {!showWorkDetails && !isMobile && (
              <LocationAndDates
                isExpanded={isExpanded}
                production={production}
                profile={profile}
                isHovered={isHovered}
                trackingData={trackingData}
              />
            )}
            {!showWorkDetails && isMobile && (
              <ProductionPeriod
                showArtistPerformedDates={showWorkDetails}
                production={production}
                isHovered={isHovered}
                profile={profile}
                trackingData={trackingData}
              />
            )}
          </div>
          <div className={classes.rightBlock}>
            <Producers
              isExpanded={isExpanded && !isMobile}
              showLocation={showWorkDetails}
              production={production}
              profile={profile}
              isHovered={isHovered}
              trackingData={trackingData}
            />
            <CastCrew
              isExpanded={isExpanded && !isMobile}
              production={production}
              hasWorkDetails={showWorkDetails}
              isGraph={isGraph}
              profile={profile}
              entityType={entityType}
              trackingData={trackingData}
            />
            {!isMobile && (
              <ProductionTags isExpanded={isExpanded} production={production} hasWorkDetails={showWorkDetails} />
            )}
          </div>
        </div>
        {!shouldSectionExpand && redirectToProductionPage && (
          <LinkButton
            styles={{ root: classnames(classes.arrowIcon, classes.arrowIcon_right) }}
            variant="text"
            onClick={onClickHandler}
            leftIcon={<Icon icon={ICONS.RIGHT} />}
            disableHover
            disableUnderline
            skipTracking
          />
        )}
      </div>
    </ImpressionTracker>
  );
};

export default ProSlug;
