import React from 'react';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import StickyContainer from 'components/Globals/Layout/StickyContainer';
import FilterFacets, { FILTER_LAYOUT } from 'components/Filters/Display/FilterFacets';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import usePageContext from 'utils/hooks/usePageContext';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import AppliedFilters from 'components/Filters/Display/AppliedFilters';
import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './ContentWithFilters.module.scss';

const Tabs = ({ tabs, activeTabKey, ns, entity, entityType, trackingData }) => {
  const { t } = useTranslation(ns);
  const { navigate } = usePageContext();
  return (
    <>
      {tabs?.length > 0 && (
        <div className={classes.tabHeader}>
          <nav className={classes.navigation}>
            <HorizontalScrollIndicators
              triggerSize={0}
              styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
            >
              {tabs.map((tab, index) => (
                <LinkButton
                  variant="grey"
                  shape="rectangle"
                  active={activeTabKey === tab.key}
                  styles={{
                    root: classes.navigation__tab,
                  }}
                  {...tab.linkProps}
                  key={`mlink_${index}`}
                  onClick={() => {
                    navigate.toTab(tab?.key, {
                      entityType,
                      entity,
                    });
                    navigate.scrollTo({ behavior: 'auto' });
                  }}
                  stopPropagation
                  disableUnderline
                  disableHover
                  isLink
                  trackingData={{
                    ...trackingData,
                    meta: {
                      tab: tab?.key,
                    },
                  }}
                >
                  {t(tab.title)}
                </LinkButton>
              ))}
            </HorizontalScrollIndicators>
          </nav>
          <div className={classes.verifiedText}>
            <Typography variant="p" size={10} color="secondary">
              <SpriteIcon icon="red_mask" size={12} className={classes.icon} />
              {t(`${TP}.FN_VERIFIED_TEXT`)}
            </Typography>
            <Typography variant="p" size={10} color="secondary">
              <SpriteIcon icon="grey_mask" size={12} className={classes.icon} />
              {t(`${TP}.FN_UNVERIFIED_TEXT`)}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

const ContentWithFilters = ({
  ns,
  filterTypes,
  disableFilters,
  tabs,
  activeTabKey,
  entity,
  entityType,
  children,
  header,
  footer,
  filterStickyTop = 75,
  isStickyHeader = true,
  hideAppliedFilters = false,
  stickyHeaderContent,
  commonFilterParams,
  clearExcludedTypes,
  disabledFilterTypes,
  tabsListTrackingData,
  filtersTrackingData,
  styles = { content: '' },
}) => {
  const isMobile = useMediaQuery('(max-width: 800px)');

  return (
    <>
      {header && (
        <StickyContainer enabled={isStickyHeader} stickyContent={stickyHeaderContent} isStickyHeader>
          <div className={classes.header}>{header}</div>
        </StickyContainer>
      )}
      <div
        className={classnames(classes.root, {
          [classes.withHeader]: header,
        })}
      >
        {isMobile && (
          <Tabs
            ns={ns}
            tabs={tabs}
            activeTabKey={activeTabKey}
            entity={entity}
            entityType={entityType}
            trackingData={tabsListTrackingData}
          />
        )}
        {filterTypes?.length > 0 && (
          <div className={classes.root__filters}>
            <StickyContainer top={filterStickyTop}>
              <FilterFacets
                types={filterTypes}
                commonFilterParams={commonFilterParams}
                clearExcludedTypes={clearExcludedTypes}
                disable={disableFilters}
                disabledTypes={disabledFilterTypes}
                layout={isMobile ? FILTER_LAYOUT.HORIZONTAL : FILTER_LAYOUT.VERTICAL}
                scrollable={isMobile}
                trackingData={filtersTrackingData}
              />
            </StickyContainer>
          </div>
        )}
        <div className={classnames(classes.root__content, { [styles.content]: !!styles.content })}>
          {!isMobile && (
            <Tabs
              ns={ns}
              tabs={tabs}
              activeTabKey={activeTabKey}
              entity={entity}
              entityType={entityType}
              trackingData={tabsListTrackingData}
            />
          )}
          <div className={classes.tab}>
            {!hideAppliedFilters && filterTypes?.length > 0 && (
              <AppliedFilters className={classes.tabFilters} clearExcludedTypes={clearExcludedTypes} />
            )}
            {children}
          </div>
        </div>
      </div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </>
  );
};

export default ContentWithFilters;
